/* eslint-disable no-mixed-spaces-and-tabs */
import i18next from 'i18next';

// Import helpers
import {
	convertTime,
	DELIVERY_STATUS,
	getDeliveryLabelsExport,
	getDeliveryTimeLabels,
	PAYMENT_METHODS,
} from 'helpers';

export const bookingsColumns = (isAdmin = false) => [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.customer_phone',
		accessor: 'customer_phone',
	},
	{
		Header: 'bookings.table.headers.customer_type',
		accessor: 'customer_type',
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
	},
	{
		Header: 'bookings.table.headers.agent_name',
		accessor: 'blamable_full_name',
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: 'pick_date',
	},
	{
		Header: 'bookings.table.headers.pick_time',
		accessor: 'pick_time',
	},
	{
		Header: 'bookings.table.headers.created_time',
		accessor: 'created_at',
	},
	{
		Header: 'common.fields.extended_at',
		accessor: 'extension_created_at',
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: 'state',
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancellation_reasons',
		subColumns: [
			{
				Header: 'bookings.table.headers.cancellation_reason',
				accessor: 'cancel_description',
			},
			{
				Header: 'bookings.table.headers.cancelled_by',
				accessor: 'cancelled_by',
				valueExtractor: ({ cancel_reason }) => {
					if (Array.isArray(cancel_reason)) return null;
					return cancel_reason.created_by_type;
				},
			},
		],
	},
	{
		Header: 'bookings.table.headers.delivery',
		accessor: 'delivery',
		valueExtractor: ({ delivery }) =>
			i18next.t(
				`common.${delivery.pick_up_delivery.is_available ? 'yes' : 'no'}`
			),
	},
	{
		Header: 'bookings.table.headers.amount_of_delivery',
		accessor: 'delivery_price',
	},
	{
		Header: 'bookings.table.headers.return',
		accessor: 'return',
		valueExtractor: ({ delivery }) =>
			i18next.t(`common.${delivery.drop_delivery.is_available ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.amount_of_return',
		accessor: 'drop_price',
	},
	{
		Header: 'bookings.table.headers.delivery_location',
		accessor: 'delivery_location',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery, pick_up_delivery } = delivery;
			const { IN_PROGRESS, PENDING } = DELIVERY_STATUS;

			const isWrongLocation =
				pick_up_delivery.has_wrong_location || drop_delivery.has_wrong_location;

			const isNotUsedTheApp =
				pick_up_delivery.is_delivery_prolonged ||
				drop_delivery.is_delivery_prolonged;

			const hasDriverAssigned =
				pick_up_delivery.has_driver_assigned ||
				drop_delivery.has_driver_assigned;

			const deliveryStatusPickup = pick_up_delivery.delivery_state;
			const deliveryStatusDropoff = pick_up_delivery.delivery_state;

			const isWaitingDelivery =
				deliveryStatusPickup === PENDING ||
				deliveryStatusDropoff === PENDING ||
				deliveryStatusPickup === IN_PROGRESS ||
				deliveryStatusDropoff === IN_PROGRESS;

			return isNotUsedTheApp || !hasDriverAssigned || isWaitingDelivery
				? ''
				: i18next.t(
						`bookings.table.common.location.${
							isWrongLocation ? 'wrong_location' : 'right_location'
						}`
				  );
		},
	},
	{
		Header: 'bookings.table.headers.pickup_delivery',
		accessor: 'pickup_delivery',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery } = delivery;
			const labels = getDeliveryLabelsExport(pick_up_delivery);

			return labels;
		},
	},
	{
		Header: 'bookings.table.headers.return_delivery',
		accessor: 'return_delivery',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery } = delivery;
			const labels = getDeliveryLabelsExport(drop_delivery);

			return labels;
		},
	},
	{
		Header: 'bookings.table.headers.used_the_app_pickup',
		accessor: 'used_the_app_pickup',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery } = delivery;
			const { is_delivery_prolonged, has_driver_assigned } = pick_up_delivery;

			return i18next.t(
				`common.${!is_delivery_prolonged && has_driver_assigned ? 'yes' : 'no'}`
			);
		},
	},
	{
		Header: 'bookings.table.headers.used_the_app_return',
		accessor: 'used_the_app_return',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery } = delivery;
			const { is_delivery_prolonged, has_driver_assigned } = drop_delivery;

			return i18next.t(
				`common.${!is_delivery_prolonged && has_driver_assigned ? 'yes' : 'no'}`
			);
		},
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: 'payment.method',
		valueExtractor: ({ payment }) => {
			const { method, method_type } = payment;
			const sectionName = 'bookings.preview_booking.invoice';

			const paymentMethodLabel = i18next.t(`${sectionName}.${method}`);

			const paymentTypeLabel = i18next.t(
				`${sectionName}.method_type.${method_type}`
			);

			const prePaidLabel = i18next.t(`${sectionName}.pre_paid`);

			switch (method) {
				case PAYMENT_METHODS.CARD:
					return isAdmin ? paymentMethodLabel : prePaidLabel;

				case PAYMENT_METHODS.WALLET:
					return isAdmin ? paymentTypeLabel : prePaidLabel;

				default:
					return paymentMethodLabel;
			}
		},
	},
	{
		Header: 'bookings.table.headers.customer_nationality',
		accessor: 'customer_nationality',
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
	},
	{
		Header: 'bookings.table.headers.drop_date',
		accessor: 'drop_date',
	},
	{
		Header: 'bookings.table.headers.pick_location',
		accessor: 'pick_location',
	},
	{
		Header: 'bookings.table.headers.drop_location',
		accessor: 'drop_location',
	},
	{
		Header: 'bookings.table.headers.total_base_nett_price',
		accessor: 'total_base_nett_price',
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_by_customer',
		accessor: 'total_discounted_gross_price',
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
		valueExtractor: ({ customer_no_booking }) => +customer_no_booking,
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: 'respond_time',
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: 'response_time',
		valueExtractor: ({ response_time }) =>
			response_time
				? `${response_time} ${i18next.t('bookings.table.common.minutes')}`
				: '',
	},
	{
		Header: 'bookings.table.headers.updated_time',
		accessor: 'updated_at',
	},
	{
		Header: 'bookings.table.headers.approved',
		accessor: 'approved',
		valueExtractor: ({ approved }) =>
			i18next.t(`common.${approved ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.admins_comment',
		accessor: 'admin_discuss',
		valueExtractor: (row) => {
			const adminComments = row.admin_discuss || [];

			const adminCommentsString = adminComments
				.map(
					({ added_at, content, full_name }) =>
						`${full_name}: (${added_at}): ${content}`
				)
				.join(', \n');

			return adminCommentsString;
		},
	},
	{
		Header: 'bookings.table.headers.license_number',
		accessor: 'license_number',
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: 'rating_reason',
		valueExtractor: (row) => {
			const { old: oldReason, new: newReason } = row.rating_reasons || {};

			const reasonKey = `reason_${i18next.language}`;

			const oldReasonString = oldReason
				? `${i18next.t(`common.old`)}: ${oldReason?.[reasonKey]}`
				: '';
			const newReasonString = newReason
				? `${i18next.t(`common.new`)}: ${newReason?.[reasonKey]}`
				: '';

			const reasons = [oldReasonString, newReasonString].filter(Boolean);

			return reasons.join(', ');
		},
	},
	{
		Header: 'bookings.table.headers.customer_email',
		accessor: 'customer_email',
	},
	{
		Header: 'bookings.table.headers.office_address',
		accessor: 'office_address',
	},
	{
		Header: 'bookings.table.headers.settlement_status',
		accessor: 'settlement_status',
		valueExtractor: ({ is_settled }) =>
			i18next.t(`common.${is_settled ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.kiosk',
		accessor: 'is_kiosk',
		valueExtractor: ({ is_kiosk }) =>
			i18next.t(`common.${is_kiosk ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.preview_booking.period.contract_opened_at',
		accessor: 'contract_opened_at',
	},
	{
		Header: 'bookings.preview_booking.period.contract_closed_at',
		accessor: 'contract_closed_at',
	},
	{
		Header: 'bookings.preview_booking.period.title',
		accessor: 'number_of_days',
	},
	{
		Header: 'bookings.table.headers.edited_by_customer',
		accessor: 'manipulation',
	},
	{
		Header: 'bookings.table.headers.difference_amount',
		accessor: 'customer_edit_booking_gross_price_diff',
		valueExtractor: (row) => {
			const {
				extension_gross_amount: extensionGrossAmount,
				total_discounted_gross_price: totalDiscount,
				old_total_gross_price: oldPrice,
			} = row || {};

			const originalAmount = totalDiscount - extensionGrossAmount;

			if (null != oldPrice && null != originalAmount) {
				const difference = Number(oldPrice) - Number(originalAmount);
				return (
					(Number(difference) <= 0 ? '' : '+') + Number(difference).toFixed(2)
				);
			} else {
				return i18next.t('common.not_available_shorthand');
			}
		},
	},
	{
		Header: 'bookings.table.headers.previous_company',
		accessor: 'old_company',
	},
	{
		Header: 'bookings.table.headers.previous_office',
		accessor: 'old_office',
	},
	{
		Header: 'bookings.table.headers.previous_car',
		accessor: 'old_car',
	},
	{
		Header: 'bookings.table.headers.previous_pickup_date',
		accessor: 'old_pickup_time',
	},
	{
		Header: 'bookings.table.headers.previous_drop_off_date',
		accessor: 'old_drop_date',
	},
	{
		Header: 'bookings.table.headers.previous_total_price',
		accessor: 'old_total_gross_price',
	},
	{
		Header: 'bookings.table.headers.extension_request_date',
		accessor: 'extension.extension_request_date',
	},
	{
		Header: 'bookings.table.headers.duration_of_extension',
		accessor: 'extension.extension_days',
	},
	{
		Header: 'bookings.table.headers.total_duration',
		accessor: 'total_duration',
		valueExtractor: (row) => {
			return `${row.pick_date} - ${row.drop_date}`;
		},
	},
	{
		Header: 'bookings.table.headers.extension_gross_price',
		accessor: 'extension_gross_amount',
		valueExtractor: ({ extension_gross_amount: extensionGrossAmount }) =>
			extensionGrossAmount &&
			i18next.t('currency.price', {
				amount: extensionGrossAmount,
			}),
	},
	{
		Header: 'bookings.table.headers.original_booking_total_price',
		accessor: 'original_booking_total_price',
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_online',
		accessor: 'partial_payment_online_paid_amount',
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_wallet',
		accessor: 'partial_payment_wallet_paid_amount',
	},
	{
		Header: 'bookings.table.headers.price_after_close_contract',
		accessor: 'contract_total_gross_price',
	},
	{
		Header: 'bookings.table.headers.service_type',
		accessor: 'service_type',
	},
	{
		Header: 'bookings.table.headers.discount_code',
		accessor: 'discount_code',
	},
	{
		Header: 'bookings.table.headers.amount_of_discount',
		accessor: 'discount_amount',
	},
	{
		Header: 'bookings.table.headers.assigned_to_driver_pickup',
		accessor: 'assigned_to_driver_pickup',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery } = delivery;

			return i18next.t(
				`common.${pick_up_delivery.has_driver_assigned ? 'yes' : 'no'}`
			);
		},
	},
	{
		Header: 'bookings.table.headers.assigned_to_driver_return',
		accessor: 'assigned_to_driver_return',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery } = delivery;

			return i18next.t(
				`common.${drop_delivery.has_driver_assigned ? 'yes' : 'no'}`
			);
		},
	},
	{
		Header: 'bookings.table.headers.assigned_driver',
		accessor: 'assigned_driver',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery, drop_delivery } = delivery;

			const { driver_name: driverNamePickup } = pick_up_delivery || {};
			const { driver_name: driverNameDropoff } = drop_delivery || {};

			const driverNamePickupText =
				(driverNamePickup &&
					`${i18next.t(
						'bookings.table.common.pickup'
					)}: ${driverNamePickup}`) ||
				'';
			const driverNameDropoffText =
				(driverNameDropoff &&
					`/ ${i18next.t(
						'bookings.table.common.return'
					)}: ${driverNameDropoff}`) ||
				'';

			return `${driverNamePickupText} ${driverNameDropoffText}`;
		},
	},
	{
		Header: 'bookings.table.headers.old_assigned_driver',
		accessor: 'old_assigned_driver',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery, drop_delivery } = delivery;

			const { old_driver_name: oldDriverNamePickup } = pick_up_delivery || {};
			const { old_driver_name: oldDriverNameDropoff } = drop_delivery || {};

			const driverNamePickupText =
				(oldDriverNamePickup &&
					`${i18next.t(
						'bookings.table.common.pickup'
					)}: ${oldDriverNamePickup}`) ||
				'';
			const driverNameDropoffText =
				(oldDriverNameDropoff &&
					`/ ${i18next.t(
						'bookings.table.common.return'
					)}: ${oldDriverNameDropoff}`) ||
				'';

			return `${driverNamePickupText} ${driverNameDropoffText}`;
		},
		isHidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.delay_time',
		accessor: 'delay_time',
		valueExtractor: ({ delivery }) => {
			const labels = getDeliveryTimeLabels(delivery);
			const convertedLabels = labels.filter((label) => label).join(' / ');

			return convertedLabels;
		},
	},
	{
		Header: 'bookings.table.headers.late_assignment',
		accessor: 'late_assignment',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery, pick_up_delivery } = delivery;
			const isLateAssignment =
				pick_up_delivery.has_late_assigment || drop_delivery.has_late_assigment;

			return i18next.t(`common.${isLateAssignment ? 'yes' : 'no'}`);
		},
	},
	{
		Header: 'bookings.table.headers.cancellation_reason_by_driver_pickup',
		accessor: 'cancellation_reason_by_driver_pickup',
		valueExtractor: ({ delivery }) => {
			const { pick_up_delivery } = delivery;
			const { cancellation_reason: cancellationReason } =
				pick_up_delivery || {};

			return cancellationReason ? cancellationReason[i18next.language] : '';
		},
	},
	{
		Header: 'bookings.table.headers.cancellation_reason_by_driver_return',
		accessor: 'cancellation_reason_by_driver_return',
		valueExtractor: ({ delivery }) => {
			const { drop_delivery } = delivery;
			const { cancellation_reason: cancellationReason } = drop_delivery || {};
			return cancellationReason ? cancellationReason[i18next.language] : '';
		},
	},
	{
		Header: 'bookings.table.headers.subscription_bookings',
		accessor: 'is_subscription',
		valueExtractor: ({ is_subscription, montly_rate }) =>
			is_subscription
				? `${montly_rate} ${i18next.t('currency.sar')}`
				: i18next.t('common.no'),
	},
	{
		Header: 'bookings.table.headers.subscription_payments',
		accessor: 'period_duration',
		valueExtractor: ({ period_duration, total_paid_invoices }) =>
			`${total_paid_invoices}/${period_duration}`,
	},
	{
		Header: 'bookings.table.headers.early_return_fees',
		accessor: 'early_return_fees',
		valueExtractor: ({ early_return_fees }) =>
			`${early_return_fees} ${i18next.t('currency.sar')}`,
	},
	{
		Header: 'bookings.table.headers.early_return_refund',
		accessor: 'early_return_refund',
		valueExtractor: ({ early_return_refund }) =>
			`${early_return_refund} ${i18next.t('currency.sar')}`,
	},
	{
		Header: 'common.fields.paid_at',
		accessor: 'total_transactions_amount',
		valueExtractor: ({ total_transactions_amount }) =>
			`${total_transactions_amount} ${i18next.t('currency.sar')}`,
	},
	{
		Header: 'bookings.table.headers.arrival_time',
		accessor: 'delivery.pick_up_delivery.arrived_at',
	},
	{
		Header: 'bookings.table.headers.delivery_time',
		accessor: 'delivery.pick_up_delivery.finished_at',
	},
	{
		Header: 'bookings.table.headers.waiting_period',
		accessor: 'delivery.pick_up_delivery.waiting_period',
		valueExtractor: ({ delivery }) =>
			convertTime(delivery.pick_up_delivery.waiting_period),
	},
	{
		Header: 'bookings.table.headers.cancellation_fees_amount',
		accessor: 'cancellation_fees',
		isHidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.loyalty_program',
		accessor: 'loyalty_program_partner',
	},
	{
		Header: 'bookings.table.headers.amount_paid_by_loyalty_program',
		accessor: 'loyalty_program_amount',
	},
	{
		Header: 'bookings.table.headers.security_deposit',
		accessor: 'security_deposit',
	},
	{
		Header: 'bookings.table.headers.airport_name',
		accessor: 'airport_name',
	},
	{
		Header: 'bookings.table.headers.train_station_name',
		accessor: 'train_station_name',
	},
	{
		Header: 'bookings.table.headers.offline_reason',
		accessor: 'settlement_offline_reason',
		valueExtractor: ({ settlement_offline_reason: reason }) =>
			reason &&
			i18next.t(
				`bookings.preview_booking.subscription_invoice.offline_reasons.${reason}`
			),
		isHidden: !isAdmin,
	},
	{ Header: 'common.fields.cancellation_date', accessor: 'cancellation_date' },
	{
		Header: 'common.fields.airport_fee',
		accessor: 'fast_delivery_at_airport',
		isHidden: !isAdmin,
	},
	{
		Header: 'common.fields.office_commission',
		accessor: 'office_commission_percentage',
		isHidden: !isAdmin,
		valueExtractor: ({
			office_commission_percentage: officeCommissionPercentage,
		}) => (officeCommissionPercentage ? `${officeCommissionPercentage}%` : ''),
	},
	{
		Header: 'bookings.table.headers.extra_services',
		accessor: 'extra_services',
		subColumns: [
			{
				Header: 'bookings.table.headers.extra_services_baby_seat',
				accessor: 'extra_services.child_seat',
			},
			{
				Header: 'bookings.table.headers.extra_services_unlimited_km',
				accessor: 'extra_services.unlimitedKm',
			},
			{
				Header: 'bookings.table.headers.extra_services_tam',
				accessor: 'extra_services.tam',
			},
			{
				Header: 'bookings.table.headers.extra_services_extra_driver',
				accessor: 'extra_services.extra_driver',
			},
			{
				Header: 'bookings.table.headers.extra_services_travel_auth',
				accessor: 'extra_services.auth_out_ksa',
			},
			{
				Header: 'bookings.table.headers.extra_services_cdw',
				accessor: 'extra_services.insurance',
			},
			{
				Header: 'bookings.table.headers.extra_services_fast_airport_delivery',
				accessor: 'extra_services.fast_delivery_at_airport',
			},
			{
				Header: 'bookings.table.headers.extra_services_non_smoke',
				accessor: 'extra_services.no_smoking_car',
			},
			{
				Header: 'bookings.table.headers.extra_services_delivery_and_return',
				accessor: 'extra_services.delivery_drop',
			},
			{
				Header: 'bookings.table.headers.extra_services_chauffeur',
				accessor: 'extra_services.driver',
			},
			{
				Header: 'bookings.table.headers.extra_services_intercity_drop_off',
				accessor: 'extra_services.dropCity',
			},
		],
		isHidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.refund_amount',
		accessor: 'refund_amount',
		hidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.admin_manual_amount',
		accessor: 'admin_manual_amount',
		hidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.auto_low_rating_amount',
		accessor: 'auto_low_rating_amount',
	},
	{
		Header: 'bookings.table.headers.extra_charges',
		accessor: 'extra_charges',
		subColumns: [
			{
				Header: 'bookings.table.headers.extra_charges_extra_days',
				accessor: 'extra_days',
			},
			{
				Header: 'bookings.table.headers.extra_charges_extra_days_amount',
				accessor: 'extra_days_price',
			},
			{
				Header: 'bookings.table.headers.extra_charges_extra_hours',
				accessor: 'extra_hours',
			},
			{
				Header: 'bookings.table.headers.extra_charges_extra_hours_amount',
				accessor: 'extra_hours_price',
			},
			{
				Header: 'bookings.table.headers.extra_charges_car_damage_price',
				accessor: 'car_damages_price',
			},
			{
				Header: 'bookings.table.headers.extra_charges_fuel_refill_charge',
				accessor: 'fuel_refill_price',
			},
			{
				Header: 'bookings.table.headers.extra_charges_violations_charge',
				accessor: 'violations_price',
			},
			{
				Header: 'bookings.table.headers.extra_charges_extra_km',
				accessor: 'extra_kilometer',
			},
			{
				Header: 'bookings.table.headers.extra_charges_extra_km_charge',
				accessor: 'extra_kilometer_price',
			},
		],
		isHidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.total_charges',
		accessor: 'total_charges',
		isHidden: !isAdmin,
	},
	{
		Header: 'common.fields.partner_booking_reference_no',
		accessor: 'partner_booking_id',
	},
];
