import services from 'services/services';
import * as types from 'store/types';
import {
	bookingCancelReasonsUrl,
	updateStatusBookingUrl,
	fetchBookingsUrl,
	bookingStatesUrl,
} from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams, ENDPOINTS } from 'helpers';

export const fetchBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const bookingsPromise = services.get(
				fetchBookingsUrl(params),
				cancelToken
			);

			const cancelReasonsPromise = services.get(
				bookingCancelReasonsUrl,
				cancelToken
			);

			const bookingStatesPromise = services.get(bookingStatesUrl, cancelToken);

			const values = await Promise.all([
				bookingsPromise,
				cancelReasonsPromise,
				bookingStatesPromise,
			]);

			const [{ data }, { data: cancelReasons }, { data: states }] = values;

			const resources = {
				cancelReasons: cancelReasons.data,
				states: states.data,
			};

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateStatusBooking =
	({ id, cancelId, type }) =>
	async () => {
		try {
			const { REJECT, CANCEL } = {
				CANCEL: 'cancel',
				REJECT: 'reject',
			};

			const deleteOption = {
				cancel_reason: {
					uuid: cancelId,
				},
			};
			const options = type === REJECT || type === CANCEL ? deleteOption : {};

			// Get response
			await services.put(updateStatusBookingUrl(id, type), options);

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	};

export const setValidationTableChangedState = ({
	isTableChanged,
	dispatch,
}) => {
	const type = isTableChanged
		? types.BOOKINGS_VALIDATION_NEED_TO_REFRESH
		: types.BOOKINGS_VALIDATION_NO_NEED_TO_REFRESH;

	dispatch({ type });
};

export const openContract = (uuid) => {
	return services.put(ENDPOINTS.BOOKINGS.OPEN_CONTRCAT(uuid));
};
